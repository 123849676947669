import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import { calculateAge } from "../../common_var/common";

const DateValidator = ({
  name,
  value,
  isTouched,
  labelName,
  minYear,
  maxDate,
  condition,
  required
}) => {
  const [{ formData, formSubmitted, requiredFields }, dispatch] = useStateValue();
  let d = new Date(value);

  const removeError = () => {
    dispatch({
      type: "REMOVE_ERROR",
      payload: name,
    });
  };

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (formData[name] == "Invalid Date") {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `Invalid` },
        });
      } else if (condition) {
        if (condition === "min_18") {
          const age = calculateAge(`${value}`);
          if (age < 18) {
            dispatch({
              type: "SET_ERRORS",
              payload: { [name]: `must be greater than 18 years` },
            });
          } else removeError();
        } else if (condition === "min_50") {
          const year = calculateAge(`${value}`);
          if (year < 50) {
            dispatch({
              type: "SET_ERRORS",
              payload: { [name]: `must be greater than 50 years` },
            });
          } else removeError();
        } else if (condition === "l_cdate") {
          const currentDate = new Date();
          if (new Date(value) > currentDate) {
            dispatch({
              type: "SET_ERRORS",
              payload: { [name]: `value must be less than current date` },
            });
          } else removeError();
        } else if (condition === "g_cdate") {
          const currentDate = new Date();
          if (new Date(value) < currentDate) {
            dispatch({
              type: "SET_ERRORS",
              payload: { [name]: `value must be greater than current date` },
            });
          } else removeError();
        } else {
          removeError()
        }
      } else if ((d > new Date() || d.getFullYear() < minYear) && name !== "expiry_date") {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `Invalid` },
        });
      } else if (
        name === "date_of_birth" ||
        name === "coSignor_date_of_birth"
      ) {
        if (Math.abs(new Date() - d) <= 567993600000) {
          dispatch({
            type: "SET_ERRORS",
            payload: { [name]: `Should be greater than 18 years` },
          });
        } else {
          removeError();
        }
      } else {
        removeError();
      }
    }
  }, [value, isTouched, formData, formSubmitted]);

  useEffect(() => {
    dispatch({
      type: 'SET_VALUES',
      payload: {[name]: value ?? null}
    })

    if(required) {
      const isExist = requiredFields?.find(item => item.name === name);
      !isExist && dispatch({
        type: "SET_REQUIRED_FIELDS",
        payload: { name, Fieldtype: "date" } 
      })
    }
  }, [name]);

  return <></>;
};

export default DateValidator;

//  else if (name == "date_of_hire" && formData[name] > maxDate) {
//         dispatch({
//           type: "SET_ERRORS",
//           payload: { [name]: `should not be within last 60 days` },
//         });
//       }
