import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { initialState, reducer } from "./Context/Reducer";
import { StateProvider } from "./Context/StateProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";

// Axios.interceptors.request.use(
//   function (config) {
//     const remove =
//       "http://localhost:3001/admin-api/api/flink" ||
//       "https://health-wf-qa-admin.theecentral.com/admin-api/api/flink" ||
//       "https://health-wf-dev-admin.theecentral.com/admin-api/api/flink" ||
//       "http://localhost:3001/admin-api/offers/flinksResponse/" ||
//       "https://health-wf-qa-admin.theecentral.com/admin-api/offers/flinksResponse/" ||
//       "https://health-wf-dev-admin.theecentral.com/admin-api/offers/flinksResponse/";
//     const removeLoading = config?.url?.includes(remove);
//     if (removeLoading) {
//       return config;
//     }
//     // document.body.classList.add(" ");
//     var div1 = document.createElement("div");
//     div1.setAttribute("class", "loderBlock");
//     div1.setAttribute("id", "healthLoader");
//     var div2 = document.createElement("div");
//     // div2.setAttribute("class", "pulse");
//     div1.appendChild(div2);
//     document.body.appendChild(div1);
//     return config;
//   },
//   function (error) {
//     // document.body.classList.remove(" ");
//     const element = document.getElementById("healthLoader");
//     element?.remove();
//     return Promise.reject(error);
//   }
// );

// Axios.interceptors.response.use(
//   function (response) {
//     const remove =
//       "http://localhost:3001/admin-api/api/flink" ||
//       "https://health-wf-qa-admin.theecentral.com/admin-api/api/flink" ||
//       "https://health-wf-dev-admin.theecentral.com/admin-api/api/flink" ||
//       "http://localhost:3001/admin-api/offers/flinksResponse/" ||
//       "https://health-wf-qa-admin.theecentral.com/admin-api/offers/flinksResponse/" ||
//       "https://health-wf-dev-admin.theecentral.com/admin-api/offers/flinksResponse/";
//     const removeLoading = response.config.url.includes(remove);
//     if (removeLoading) {
//       return response;
//     }

//     // spinning hide
//     const element = document.getElementById("healthLoader");
//     element?.remove();
//     // document.body.classList.remove("loading-indicator");
//     return response;
//   },
//   function (error) {
//     // document.body.classList.remove("loading-indicator");
//     const element = document.getElementById("healthLoader");
//     element?.remove();
//     return Promise.reject(error);
//   }
// );

Axios.interceptors.request.use(
  function (config) {
    const remove =[
    "http://localhost:3001/admin-api/api/flink",
    "https://health-wf-qa-admin.theecentral.com/admin-api/api/flink",
    "https://health-wf-dev-admin.theecentral.com/admin-api/api/flink",
    "http://localhost:3001/admin-api/loan/flinksResponse/",
    "https://health-wf-qa-admin.theecentral.com/admin-api/loan/flinksResponse/",
    "https://health-wf-dev-admin.theecentral.com/admin-api/loan/flinksResponse/"]
  const removeLoading = remove.some((url) => config?.url?.includes(url));
  if (removeLoading) {
    return config;
  }
    let div1 = document.createElement("div");
    div1.setAttribute("id", "spinnerloader");
   let main = `
   <div class="preloader">
    <div class="preloader-inner">
      <div class="loader">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="subline"></div>
        <div class="subline"></div>
        <div class="subline"></div>
        <div class="subline"></div>
        <div class="subline"></div>
        <div class="loader-circle-1">
          <div class="loader-circle-2"></div>
        </div>
        <div class="needle"></div>
        <div class="loading">Loading</div>
      </div>
      </div>
    </div>`
    div1.innerHTML= main
    // div1.append(main)
    document.body.appendChild(div1);
    return config;
  },
  function (error) {
    // document.body.classList.remove(" ");
    // div1.removeChild(div1.firstChild);
    const element = document.getElementById("spinnerloader");
    element?.remove();
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    const remove =[
    "http://localhost:3001/admin-api/api/flink",
    "https://health-wf-qa-admin.theecentral.com/admin-api/api/flink",
    "https://health-wf-dev-admin.theecentral.com/admin-api/api/flink",
    "http://localhost:3001/admin-api/offers/flinksResponse/",
    "https://health-wf-qa-admin.theecentral.com/admin-api/offers/flinksResponse/",
    "https://health-wf-dev-admin.theecentral.com/admin-api/offers/flinksResponse/"]
  const removeLoading = remove.some((url) => response?.url?.includes(url));
  if (removeLoading) {
    return response;
  }
    // div1.removeChild(div1.firstChild);
    // spinning hide
    const element = document.getElementById("spinnerloader");
    element?.remove();
    // document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    // document.body.classList.remove("loading-indicator");
    const element = document.getElementById("spinnerloader");
    element?.remove();
    // div1.removeChild(div1.firstChild);
    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
