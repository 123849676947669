import React, { useEffect, useState } from "react";
import { AuthGet, AuthPost, Get, Post } from "../../common_var/httpService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BanksImage from "../../assets/images/bank.png";
import Image from "../Image/Image";
import Button from "../Button/Button";
import CirclecloseIcon from "../../assets/images/circleClose_icon.svg";
import Modal from "react-bootstrap/Modal";
import SpinningLoader from "../SpinningLoader/Spinner";
import { useStateValue } from "../../Context/StateProvider";
import SkipButton from "../SkipButton/SkipButton";
import toast from "react-hot-toast";

export const Flink = ({ step, skip, goNextStep }) => {
  const [{  currentStep, frames, isCosignorFlow }, dispatch] = useStateValue();
  const { loanid } = useParams();
  const navigate = useNavigate();
  const queryParams = useLocation();
  let url = window.location.href;
  let isLocalhost = url.toString().includes("localhost") ? true : false;
  const params = queryParams.pathname.split("/");
  const [isLoading, setIsloading] = useState(true);
  const [show, setShow] = useState(false);
  const userId = sessionStorage.getItem("user_id");

  const getProduct = async (loginId, reqId) => {
    return await AuthPost(
      `loan/flinksResponse/${reqId}`,
      {
        loan_id: loanid,
        user_id: userId,
        step: step,
        login_id: loginId,
      },
      "onboarding"
    )
      .then((res) => {
        if (res?.HttpStatusCode === 200) {
          console.log("res::: ", res);
          return res;
        } else {
          return res;
        }
      })
      .catch((err) => {
        console.log("err::: ", err);
      });
  };

  useEffect(() => {
    const handleMessage = async function (e) {
      if (e?.data?.step === "APP_MOUNTED") {
        setIsloading(false);
      }
      if (e?.data?.step === "REDIRECT") {
        console.log("e?.data?.loginId::: ", e?.data?.loginId);
        let flinkLoginId = e?.data?.loginId
        toast.success("Connected to Your bank and Verifying the details");
        handleClose();
        try {
          await AuthPost(
            `loan/flinks-integrate/${loanid}`,
            { login_id: e?.data?.loginId, step },
            "onboarding"
          )
            .then(async (res) => {
              if (res?.statusCode === 200 && res?.data?.RequestId) {
                let reqId = res?.data?.RequestId
                if (goNextStep) {
                  redirectFrame(res["stepDt"][0]?.step);
                } else {
                  window.open(
                    `${process.env.REACT_APP_UI_URL}/borrower/login`,
                    "_self"
                  );
                }
                getProduct(flinkLoginId, reqId);

                // Remove the event listener after successful API call
                window.removeEventListener("message", handleMessage);
              }
            })
            .catch((err) => {
              console.log("err::: ", err);
            });
        } catch (error) {
          console.log("error::: ", error);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const postData = async (bodyData) => {
    try {
      bodyData.loan_id = loanid;
      bodyData.step = step;
      const resp = await AuthPost("api/flink", bodyData, "admin");
      if (resp.status === 200) {
        //success
        console.log("Success::", resp);
      } else {
        //failure
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const redirectFrame = (nextStep) => {
    console.log("Next step::", nextStep);
    dispatch({
      type: "SET_STEP",
      payload: nextStep,
    });

    const nextFrame = frames.filter(el => el.step === currentStep + 1);
    dispatch({
      type: 'SET_CURRENT_FRAME',
      payload: nextFrame[0]?.name,
    });
  }

  return (
    <div>
      <div>
        <Image src={BanksImage} alt={"bank_banner"} classname="imagewidth" />
        <div className="displayFlex">
        {skip && <SkipButton />}
          <Button
            type={"submit"}
            value={"Click to link your Bank "}
            handleClick={() => setShow(true)}
          />
        </div>
      </div>

      <Modal show={show} className="getstartedPopup">
        <div className="textAlignRight p16 popupcloseButton">
          <button className="emptyButton" onClick={handleClose}>
            <img src={CirclecloseIcon} />
          </button>
        </div>
        <Modal.Body>
          {isLoading && (
            <div
              style={{
                width: "60vh",
                height: "100vh",
                paddingTop: "50vh",
                paddingLeft: "10vh",
                textAlign: "center",
              }}
            >
              <SpinningLoader />
            </div>
          )}
          <iframe
            class="flinksconnect"
            height="760"
            src="https://toolbox-iframe.private.fin.ag/?demo=true&redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true"
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
};
