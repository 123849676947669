import React from "react";
import { AuthPost } from "./httpService";
import {useStateValue} from "../../src/Context/StateProvider"
import { useParams } from "react-router-dom";




export const comparingTwoObjs = (obj1, obj2) => {
    let result;
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return result = false;
      let firstObj = Object.keys(obj1);
      for (let k of firstObj) {
        if (obj1[k] === obj2[k]) {
          result = true;
        } else {
          result = false;
          break;
        }
      }
    return result;
  };
  
  
  export const calculateAge = (val, minyear = 1970) => {
    const dob = new Date(val);
    const month_diff = Date.now() - dob.getTime();
    const age_dt = new Date(month_diff);
    const year = age_dt.getUTCFullYear();
    const age = Math.abs(year - minyear);
    return age;
  };
  



  export const SkipOnCode = async (loanid, globalStep, currentStep, frames , dispatch) => {
  //   const { loanid } = useParams();
  // const [{ globalStep, currentStep, frames }, dispatch] = useStateValue();
  const step = currentStep + 1;
      try {
  
        if (globalStep < step) {
          const res = await AuthPost(
            `api/skip/${step}/${loanid}`,
            {},
            "onboarding"
          );
          if (res.statusCode === 200) {
            dispatch({
              type: "SET_STEP",
              payload: step,
            });
            dispatch({
              type: "SET_CURRENT_STEP",
              payload: step,
            });
            const nextFrame = frames.filter((el) => el.step === step);
            dispatch({
              type: "SET_CURRENT_FRAME",
              payload: nextFrame[0]?.name,
            });
          }
        } else {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: step,
          });
          const nextFrame = frames.filter((el) => el.step === step);
          dispatch({
            type: "SET_CURRENT_FRAME",
            payload: nextFrame[0]?.name,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
  
