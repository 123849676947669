import React, { useState, useEffect } from "react";
import BanksImage from "../../assets/images/bank.png";
import Image from "../Image/Image";
import Button from "../Button/Button";
import { usePlaidLink } from "react-plaid-link";
import { AuthGet, AuthPost, Get, Post } from "../../common_var/httpService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { env_variables } from "../../common_var/env";
import { useStateValue } from "../../Context/StateProvider";
import ActiveBankIcon from "../../assets/images/active_bank.svg";
import CustomModal from "../Modal/Model";
import { toast } from "react-hot-toast";
import SkipButton from "../SkipButton/SkipButton";
import { SkipOnCode } from "../../common_var/common"; 
const Plaid = ({
  step,
  skip,
  goNextStep,
  setShowconnectway,
  getpaymentfrommodal,
  setSelectedprimaryaccount,
}) => {
  let addbank = "Add new bank";
  let addbankurl = { url: `payment/addBank`, key: "borrower" };
  const [bank, setBank] = useState([]);
  const [secondtime, setSecondtime] = useState(false);
  const [closeplaid, setCloseplaid] = useState(false);
  const [bankconnected, setBankconnected] = useState(false);
  const [{ frames, currentStep, isCosignorFlow, globalStep }, dispatch] =
    useStateValue();
  const [plaidToken, setPlaidToken] = useState(null);
  const [user_id, setUser_id] = useState("");
  const { loanid } = useParams();
  const navigate = useNavigate();
  const queryParams = useLocation();
  let url = window.location.href;
  let isLocalhost = url.toString().includes("localhost") ? true : false;
  const params = queryParams.pathname.split("/");
  const [shows, setShoww] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const [nextStep, setNextStep] = useState(step + 1);

  let userid = sessionStorage.getItem("user_id");
  let getpaymethod = {
    url: `payment/list/${userid}/${loanid}`,
    key: "borrower",
  };
  let changeactive = { url: `payment/setPrimary/${user_id}`, key: "borrower" };
  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    if (getpaymentfrommodal) {
      getToken();
    } else {
      bankcheck();
    }

    // if (bankconnected  && !getpaymentfrommodal) {
    //   setOpenmodal(true);
    // }
    userid = sessionStorage.getItem("user_id");
  }, []);
  const handleClose = () => {
    setShoww(false);
  };

  const getToken = async () => {
    try {
      let res = await AuthGet("loan/fetch-plaid-token/" + loanid, "onboarding");
      if (res.statusCode === 200) {
        setPlaidToken(res.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let changeBankAcct = async (e) => {
    console.log("Bank.url->>>>>", changeactive.url);

    let data = {
      bank_id: e,
      loanid,
    };
    console.log("sasasas", data);
    await AuthPost(changeactive.url, data, changeactive.key)
      .then((res) => {
        console.log("bank update", res);
        if (res.statusCode === 200) {
          toast.success(res.message[0]);
          getpayment();
        }
      })
      .catch((err) => {
        console.log("err--->", err);
      });
  };

  const showAccountNumber = (number) => {
    console.log("ldsoah", number);
    let result = "";
    for (let i = 0; i < number.length - 4; i++) {
      result = result + "X";
    }
    console.log(result + number.substring(number.length - 4));
    return result + number.substring(number.length - 4);
  };

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: async (public_token, metadata) => {
      var sendData = { public_token: public_token, step: step };
      console.log(metadata);

      await AuthPost(
        "loan/Store-plaid-pub-token/" + loanid,
        sendData,
        "onboarding"
      ).then(
        (res) => {
          if (res["statusCode"] == 200) {
            //  debugger
            // setNextStep(res["stepDt"][0]?.step)
            setOpenmodal(true);
            setUser_id(res.user_id);
            let data = res.resData;

            setBank(res.resData);
            sessionStorage.setItem("user_id", res.user_id);
            if (getpaymentfrommodal) {
              getpaymentfrommodal();

              setShowconnectway(false);
            }

            //  //write condition for this
            //  if(selectedprimaryaccount){
            //   getSystemGenerateOffers();
            //  }
          } else {
            let msg;
            toast.error(res.message);
            try {
              msg = res["message"][0];
            } catch (e) {
              msg = "Something problem try later.";
            }
            // this.service.showError(msg);
          }
        },
        (err) => {
          this.service.showError("Something problem try later.");
          console.log(err);
        }
      );
    },
    onEvent: (eventName, metadata) => {
      console.log(eventName);
    },
    onExit: (error, metadata) => {
      if (error != null) {
        toast.error('Bank is not connected')
        SkipOnCode(loanid, globalStep, currentStep, frames , dispatch)
    }
    },
  });

  const getpayment = async () => {
    // debugger
    await AuthGet(getpaymethod.url, "borrower")
      .then((res) => {
        console.log("get pay---->", res);
        if (res.data.statusCode == 403 || res.data.statusCode == 401) {
          toast.error(res.data.message);
        }
        let data = res.data.bankDetails;
        data.map((ele) => {
          ele.maskedAccountNumber = showAccountNumber(ele.accountnumber);
          if (ele.active_flag == "Y") {
            console.log(ele.active_flag);
            if (getpaymentfrommodal) {
              setSelectedprimaryaccount(true);
            }
          }
        });
        // if(data.length==1){
        //   if(data[0].active_flag=='N'){
        //     changeBankAcct(data[0].id)
        //   }
        //   }
        setBank(data);
        if (setSelectedprimaryaccount) {
          let checkdata = data.map((ele) => {
            if (ele.active_flag == "Y") {
              console.log("active_flag", ele.active_flag);
              setSelectedprimaryaccount(true);
            }
            console.log("inside", data, checkdata);
          });
        }

        // setCard(res.data.cardDetails)
      })
      .catch((err) => {
        console.log("get pay ------>", err);
      });
  };

  const redirectFrame = () => {
    console.log("step", nextStep);
    dispatch({
      type: "SET_STEP",
      payload: nextStep,
    });

    const nextFrame = frames.filter((el) => el.step === currentStep + 1);
    dispatch({
      type: "SET_CURRENT_FRAME",
      payload: nextFrame[0]?.name,
    });
  };

  const getSystemGenerateOffers = async () => {
    let res = await AuthGet(
      `loan/originate-offer/${step}/${loanid}`,
      "onboarding"
    );
    if (res["statusCode"] == 200) {
      console.log("params", params);

      if (res["data"].length == 0) {
        let urlsplit = url.split("//");
        urlsplit = urlsplit[1].toString().split(".");

        if (params.length == 5) {
          setShoww(true);
          setTimeout(() => {
            isLocalhost
              ? navigate(`/${params[1]}`)
              : navigate(
                  `${urlsplit[1]}.${process.env.REACT_APP_DOMAIN}/${params[1]}`
                );
          }, 5000);
        } else {
          setShoww(true);
          setTimeout(() => {
            isLocalhost
              ? navigate(`/`)
              : navigate(`${urlsplit[1]}.${process.env.REACT_APP_DOMAIN}/`);
          }, 5000);
        }
        console.log("Invalid");
      }
      let data = res["data"];
      if (data.length > 0) {
        dispatch({
          type: "SET_OFFERS",
          payload: data,
        });

        dispatch({
          type: "SET_STEP",
          payload: isCosignorFlow
            ? res.data[0].active_cosignor_step
            : res.data[0].step,
        });

        const nextFrame = frames.filter((el) => el.step === currentStep + 1);
        dispatch({
          type: "SET_CURRENT_FRAME",
          payload: nextFrame[0]?.name,
        });
        // toast.success("Success");
      }
    } else {
      console.log("Invalid");
    }
  };

  const bankcheck = async () => {
    await AuthGet(`loan/fetch-bankconnect/${userid}`, "admin")
      .then((res) => {
        getToken();
        if (res.statusCode == 200) {
          if (!getpaymentfrommodal) {
            setOpenmodal(true);
          }
        }
      })
      .catch((err) => {
        console.log("get pay ------>", err);
      });
  };
  return (
    <div>
      <div>
        <Image src={BanksImage} alt={"bank_banner"} classname="imagewidth" />
        <div className="displayFlex ">
          {getpaymentfrommodal || !skip ? "" : <SkipButton />}
          <Button
            type={"submit"}
            value={"Click to link your Bank "}
            handleClick={() => {
              open();
            }}
          />
        </div>
      </div>

      <div>
        <Modal show={shows} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "20px", textAlign: "center" }}>
              Unfortunately, you don't have any offers.
            </div>
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              Please contact {env_variables?.helpMail}
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        {openmodal ? (
          <CustomModal
            bank={bank}
            setBank={setBank}
            openmodal={openmodal}
            getpayment={getpayment}
            userid={user_id}
            redirectFrame={redirectFrame}
            setOpenmodal={setOpenmodal}
            integration={"plaid"}
            closeplaid={closeplaid}
            goNextStep={goNextStep}
            setNextStep={setNextStep}
            step={step}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Plaid;
