import React, { useEffect, useState } from "react";
import EmploymentInformation from "../Templates/EmploymentInformation/EmploymentInformation";
import FinancialDetails from "../Templates/FinancialDetails/FinancialDetails";
import PersonalInformation from "../Templates/PersonalInformation/PersonalInformation";
import PropertyOwnership from "../Templates/PropertyOwnership/PropertyOwnership";
import Navbar from "../Components/Navbar/Navbar";
import MultistepProgressbar from "../Components/MultistepProgressbar/MultistepProgressbar";
import { useStateValue } from "../Context/StateProvider";
import { AuthGet, Get, AuthPost } from "../common_var/httpService";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Offers from "../Templates/Offers/Offers";
import ContractSignature from "../Templates/ContractSignature/ContractSignature";
import Banklookup from "../Templates/bank-lookup/bank-lookup";
import EducationalInformation from "../Templates/EducationalInformation/EducationalInformation";
import VehicleInformation from "../Templates/VehicleInformation/VehicleInformation";
import SubmitApplication from "../Components/Submit-Application/SubmitApplication";
import CoBorrower from "../Components/Add-coborrower/Coborrower";
import Brandlogo from "../assets/images/brand_logo.svg";
import { SaveDraft } from "../Templates/save-draft/SaveDraft";

const Sales = () => {
  const [
    { globalStep, currentStep, isCosignorFlow, framesLength, isFullPreview },
    dispatch,
  ] = useStateValue();
  // console.log('isCosignorFlow::: ', isCosignorFlow);

  const { loanid, token } = useParams();
  const [userid, Setuserid] = useState("");
  const params = useLocation();
  // const cosignor = params.pathname.split("/")[1];
  const cosignor = params.pathname.includes("cosignor");
  const fullView = params.pathname.includes("full-view");
  const fullViewLoanId = params.pathname.split("/")[2];
  const navigate = useNavigate();
  const [coborrowerStep, SetCobborowerStep] = useState(0);

  const [steps] = useState([
    {
      id: 1,
      name: "Vehicle Information",
      component: <VehicleInformation step={1} />,
      content:
        "The vehicle information is a must for auto loan. If you have any challenges, reach us through comments",
    },
    {
      id: 2,
      name: "Personal",
      component: <PersonalInformation step={2} />,
      content:
        "Furnish your personal details which will help us reach out to you soon",
    },
    {
      id: 3,
      name: "Work",
      component: <EmploymentInformation step={3} />,
      content:
        "A complete history of employment status is needed from your end",
    },
    {
      id: 4,
      name: "Financials",
      component: <FinancialDetails step={4} />,
      content: "Furnish your latest monthly income and mortgage details",
    },
    {
      id: 5,
      name: "Property",
      component: <PropertyOwnership step={5} />,
      content: "Select your type of residence & share associated details",
    },
    {
      id: 6,
      name: "Educational Information",
      component: <EducationalInformation step={6} />,
      content:
        "Enter details of your educational history starting with first  year of secondary school",
    },
    {
      id: 7,
      name: "Bank Lookup",
      component: <Banklookup step={7} skip={true} goNextStep={true} />,
      content:
        "Connect securely to your Bank for us to generate the best offer for you.",
    },
    // {
    //   id: 8,
    //   name: "Available Offers",
    //   component: <Offers step={8} />,
    //   content: "",
    // },
    // {
    //   id: 8,
    //   name: "Acceptance",
    //   component: <ContractSignature step={8} />,
    //   content: "",
    // },
  ]);

  useEffect(() => {
    if (fullView) {
      sessionStorage.setItem("loan_id", loanid);
      dispatch({
        type: "SET_FULLVIEW_PREVIEW",
        payload: true,
      });
    } else {
      sessionStorage.setItem("borrower_token", token);
      sessionStorage.setItem("loan_id", loanid);
      dispatch({
        type: "SET_FRAMES_LENGTH",
        payload: steps?.length,
      });
    }
  }, [framesLength]);

  const getStep = async () => {
    try {
      const resp = await AuthGet(`loan/fetch-step/${loanid}`, "admin");
      if (resp.statusCode === 200 && !cosignor) {
        dispatch({
          type: "SET_STEP",
          payload: resp.data[0].step,
        });

        SetCobborowerStep(resp.data[0].active_cosignor_step);
        sessionStorage.setItem("user_id", resp.data[0].user_id);

        const frame = steps?.filter((el) => el.id === resp?.data[0].step);

        dispatch({
          type: "SET_CURRENT_FRAME",
          payload: frame[0].name,
        });

        // const allFrames = steps.map((el) => {
        //   return {
        //     step: el.id,
        //     name: el.name,
        //   };
        // });

        // dispatch({
        //   type: "SET_FRAMES",
        //   payload: allFrames,
        // });
      } else {
        let userId = JSON.parse(atob(token.split(".")[1]))?.user_id;
        sessionStorage.setItem("user_id", userId);
        dispatch({
          type: "SET_STEP",
          payload: resp.data[0].active_cosignor_step,
        });

        dispatch({
          type: "SET_COSIGNER",
          payload: true,
        });

        dispatch({
          type: "SET_COSIGNOR_FLOW",
          payload: true,
        });
        const frame = steps?.filter(
          (el) => el.id === resp?.data[0].active_cosignor_step
        );

        dispatch({
          type: "SET_CURRENT_FRAME",
          payload: frame[0]?.name,
        });
      }
      const allFrames = steps.map((el) => {
        return {
          step: el.id,
          name: el.name,
        };
      });

      dispatch({
        type: "SET_FRAMES",
        payload: allFrames,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (id) => {
    if (id > globalStep) {
      dispatch({ type: "SET_CURRENT_STEP", payload: globalStep });
    } else if (id <= globalStep) {
      dispatch({ type: "SET_CURRENT_STEP", payload: id });

      const frame = steps.filter((el) => el.id === id);
      dispatch({
        type: "SET_CURRENT_FRAME",
        payload: frame[0].name,
      });
    }
  };
  const getWorkflow = async () => {
    try {
      const resp = await AuthGet(`api/getWorkFlow/${loanid}`, "admin");
      if (resp.status === 200) {
        dispatch({
          type: "SET_ONBOARD_CONDITIONS",
          payload: { ...resp.onboardConditions },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!isFullPreview) {
      getStep();
      getWorkflow();
    }
    // localStorage.setItem("user_id",user_id)
  }, []);

  // const handleSubmit = async () => {
  //   try {
  //     let res = await AuthPost(
  //       `loan/submit-application`,
  //       { loanid: loanid },
  //       "onboarding"
  //     );
  //     if (res.statusCode == 200) {
  //       navigate(`/thankyou`);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getFullView = async () => {
    try {
      const resp = await Get(`api/fetch/full-view/${fullViewLoanId}`, "admin");
      if (resp.statusCode === 200) {
        resp?.data?.map((ele) => {
          Object.values(ele).map((el) => {
            Object.values(el).map((elmt) => {
              dispatch({
                type: "SET_FULLVIEW_FORMDATA",
                payload: elmt,
              });
            });
          });
        });
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  useEffect(() => {
    isFullPreview && getFullView();
  }, [isFullPreview]);

  return isFullPreview ? (
    <div>
      <div className="container plr100">
        <div className="">
          <section className="verticalStepbar">
            {steps.map(
              (ele, index) =>
                ele.name !== "Bank Lookup" &&
                ele.name !== "Available Offers" &&
                ele.name !== "Acceptance" && (
                  <div key={index} className={`verticalStepbarItem ready`}>
                    <div className="Stepheader">
                      <div className="circle">
                        <span>{ele.id}</span>
                      </div>
                      <div>
                        <h2>{ele.name}</h2>
                        <p className="">{ele.content}</p>
                      </div>
                    </div>
                    <div className="stepbody">
                      {index !== steps.length - 4 ? (
                        <div className="line"></div>
                      ) : (
                        <></>
                      )}
                      <div
                        style={
                          index === steps.length - 4
                            ? { marginLeft: "45px" }
                            : {}
                        }
                        className="stepContent"
                      >
                        {ele.component}
                      </div>
                    </div>
                  </div>
                )
            )}
          </section>
        </div>
      </div>
    </div>
  ) : (
    <div className="divCenter">
      {/* <Navbar /> */}

      <div className="displayFlex onboardingFrame">
        <MultistepProgressbar />

        <div className="rightFrameSection">
          <div className="onboardingHeader">
            <div className="displayFlex spaceBetween AlignItemCenter">
              <div>
                <div>
                  <div className="col-md-12 logo-header brand_logo mobile-logo">
                    <img src={Brandlogo} />
                  </div>
                  <h2 className="heading30   ">Your Loan Application</h2>
                </div>

                <p className="paragraphText   ">
                  Please fill in the following sections completely & accurately
                  to apply for your loan
                </p>
              </div>
              <div className="">
                {" "}
                <SaveDraft />
              </div>
            </div>
          </div>
          <div className="">
            <div className=" ">
              <section className="verticalStepbar">
                {steps.map((ele, index) => (
                  <div
                    key={index}
                    className={`verticalStepbarItem ${
                      currentStep === ele.id
                        ? "ready"
                        : globalStep > ele.id
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="Stepheader">
                      <div
                        className="circle"
                        onClick={() => handleClick(ele.id)}
                      >
                        <span>{ele.id}</span>
                      </div>
                      <div>
                        <h2>{ele.name}</h2>
                        <p className="">{ele.content}</p>
                      </div>
                    </div>
                    <div className="stepbody">
                      {index !== steps.length - 1 ? (
                        <div className="line"></div>
                      ) : (
                        <></>
                      )}
                      <div className="stepContent">
                        {currentStep === ele.id ? ele.component : <></>}
                      </div>
                    </div>
                  </div>
                ))}
              </section>
              {globalStep == steps.length + 1 && (
                <>
                  <div className="mtb10 displayFlex">
                    {!cosignor && coborrowerStep === 0 && <CoBorrower />}
                 
                  <SubmitApplication
                    type={"submit"}
                    value={"Submit Application"}
                    cosignor={cosignor}
                    // handleClick={handleSubmit}
                  />
                   </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
