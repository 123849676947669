import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import DateField from "../../Components/Date/DateField";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Email from "../../Components/Email/Email";
import Text from "../../Components/Text/Text";
import { useStateValue } from "../../Context/StateProvider";
import SkipButton from "../../Components/SkipButton/SkipButton";
import { hasNullValueInObject } from "../../common_var/util";

const EducationalInformation = ({ setStep }) => {
  const [
    {
      formData,
      errors,
      formSubmitted,
      currentStep,
      isFullPreview,
      isCosignorFlow,
      requiredFields,
    },
    dispatch,
  ] = useStateValue();
  const { loanid } = useParams();
  const [disableBtn, setDisableBtn] = useState(false);

  const degree_level = [
    { key: "professional certificates", value: "Professional certificates" },
    { key: "undergraduate degrees", value: "Undergraduate Degrees" },
    { key: "transfer degrees", value: "Transfer Degrees" },
    { key: "associate degrees", value: "Associate Degrees" },
    { key: "bachelor degrees", value: "Bachelor Degrees" },
    { key: "graduate degrees", value: "Graduate Degrees" },
    { key: "master degrees", value: "Master Degrees" },
    { key: "doctoral degrees", value: "Doctoral Degrees" },
    { key: "professional degrees", value: "Professional Degrees" },
    { key: "specialist degrees", value: "Specialist Degrees" },
  ];
  const select_status = [
    { key: "in progress", value: "In progress" },
    { key: "completed", value: "Completed" },
  ];

  const user_id = sessionStorage.getItem("user_id");

  const getDetails = async () => {
    try {
      let res = await AuthGet(`api/getEducationInfo/${loanid}`, "onboarding");
      if (res.status === 200) {
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    !isFullPreview && getDetails();
  }, []);

  // useEffect(() => {
  //   if (
  //     formData["school_name"] &&
  //     formData["school_email"] &&
  //     formData["degree_type"] &&
  //     formData["school_status"] &&
  //     formData["start_date"] &&
  //     formData["end_date"]
  //   ) {
  //     setDisableBtn(false);
  //   } else if (
  //     formData["school_name"] ||
  //     formData["school_email"] ||
  //     formData["degree_type"] ||
  //     formData["school_status"] ||
  //     formData["start_date"] ||
  //     formData["end_date"]
  //   ) {
  //     setDisableBtn(true);
  //   } else if (!formData["start_date"]) {
  //     dispatch({
  //       type: "CLEAR_VALUES",
  //     });
  //     setDisableBtn(false);
  //   } else {
  //     setDisableBtn(false);
  //   }
  // }, [
  //   formData["school_name"],
  //   formData["school_email"],
  //   formData["degree_type"],
  //   formData["school_status"],
  //   formData["start_date"],
  //   formData["end_date"],
  // ]);

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });

    const isFilled = hasNullValueInObject({...formData }, requiredFields);
    if(!isFilled) {
      console.log('isFilled::: ', isFilled);
      return;
    }

    try {
      const bodyData = {
        loan_id: loanid,
        step: currentStep,
        education: [{ ...formData }],
      };
      if (Object.keys(errors).length === 0) {
        const resp = await AuthPost(
          "api/saveEducationInfo",
          bodyData,
          "onboarding"
        );
        if (resp.statusCode === 200) {
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({type: "RESET_REQUIRED_FIELDS"});
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow
              ? resp.data[0].active_cosignor_step
              : resp.data[0].step,
          });
        }
      }
    } catch (error) {}
  };

  const today = new Date();
  let maxStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    today.getDate()
  );

  let maxEndDateForInprogress = new Date(today.getFullYear() + 6, "", "");

  return (
    <div>
      <form id="Educational_Information" className="row">
        <Text
          name={"school_name"}
          placeholder={"School name"}
          labelName={"School Name"}
          handleChange={handleChange}
          // required={true}
          readonly={isFullPreview ?? false}
        />
        <Email
          name={"school_email"}
          placeholder={"School Email"}
          labelName={"School Email"}
          handleChange={handleChange}
          // required={true}
          readonly={isFullPreview ?? false}
        />
        <Dropdown
          data={degree_level}
          labelName={"Degree Level"}
          name={"degree_type"}
          handleChange={handleChange}
          // required={true}
          readonly={isFullPreview ?? false}
        />
        <Dropdown
          data={select_status}
          labelName={"School Status"}
          name={"school_status"}
          handleChange={handleChange}
          // required={true}
          readonly={isFullPreview ?? false}
        />
        <DateField
          maxDate={maxStartDate}
          name={"start_date"}
          placeholder={"Start date"}
          labelName={"Start Date"}
          handleChange={handleChange}
          // required={true}
          readonly={isFullPreview ?? false}
        />
        <DateField
          maxDate={
            formData["schoolstatus"] == "in progress"
              ? maxEndDateForInprogress
              : new Date()
          }
          name={"end_date"}
          placeholder={"End date"}
          labelName={"End Date"}
          handleChange={handleChange}
          // required={true}
          readonly={isFullPreview ?? false}
        />
        {!isFullPreview && (
          <div className="displayFlex">
            <SkipButton />
            <Button
              // disabled={disableBtn}
              type={"button"}
              value={"Continue"}
              handleClick={handleSubmit}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default EducationalInformation;