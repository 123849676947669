import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import { useParams } from "react-router-dom";

export const SaveDraft = () => {
    const [
        {
          errors,
          formData,
          currentStep,
          currentFrame
        },      
        dispatch,
      ] = useStateValue(); 
    const { loanid } = useParams();
    const userId = sessionStorage.getItem("user_id");


    const handleSaveDraft = () => {
      console.log("form data::", formData);
      console.log("current step::", currentStep);
      console.log("current frame xx::", currentFrame);

      let saveDraftFlag = true;

      if(
        // currentFrame === 'Property' || 
        currentFrame === 'Bank Lookup' ||
        currentFrame === 'Available Offers' ||
        currentFrame === 'Acceptance') {
          saveDraftFlag = false;
        }

      if(saveDraftFlag) {
          if(Object.keys(formData).length > 0) {
            let bodyData = formData;

            if(currentFrame === 'Work') {
              bodyData = {
                frame: currentFrame,
                employment: [{ ...formData }],
              };
            }

            if(currentFrame === 'Financials') {
              let finFlag = false;
              for(let key in bodyData) {
                if(key !== 'frame') {
                  console.log("Val::", bodyData[key]);
                  if(bodyData[key] !== "" || bodyData[key] !== 0) {
                    finFlag = true;
                    bodyData[key] = +bodyData[key];
                  }
                }
              }

              if(!finFlag) return;
            }
            if(currentFrame === 'Educational Information') {
              bodyData = {
                education: [{ ...formData }],
                // step: currentStep
              };
            }
            
            bodyData.userId = userId;
            bodyData.frame = currentFrame;
            console.log("Body Dt::", bodyData);

            Post(`api/saveDraft/${loanid}`, bodyData, "admin")
            .then((res) => {
              console.log("Save draft res::", res);
            })
            .catch((err) => {
              console.log("Save draft err::", err);
            })
          }
        }

      //if form data is not empty and if frame is not bank/property/offers/acceptance
      //call post API
      //once saved, show a toast, draft saved successfully
    }

    return (
      <>
          <button id="saveDraft" className="MainButton btn_Primary mr_8" onClick={handleSaveDraft}>Save as Draft</button>
      </>
    )
}