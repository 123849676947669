import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import RadioValidator from "./RadioValidator";

const Radio = ({ labelName, handleChange, data, required, name, readonly, controlId, isFormGroup, alignStyle }) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div className={`${isFormGroup ? "col-md-12" : "col-md-6"} mb_24`}>
      {errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <div style={alignStyle === "horizontal" ? 
            {display: 'flex', alignItems: "center", gap: '15px', flexWrap: 'wrap'} : {}}
      >
      {data?.map((ele, i) => (
        <div className="prefer_method mtb10" key={i}>
          <input
            type="radio"
            checked={formData[name] === ele.value}
            id={`${name}_${ele.value}`}
            name={name}
            onChange={(e) => isFormGroup ? handleChange(e, ele.id, controlId) : handleChange(e)}
            value={ele.value}
            disabled={readonly}
            onBlur={() => setIsTouched(true)}
            style={errors[name] ? { accentColor: "#f44336" } : null}
          />
          <label htmlFor={`${name}_${ele.value}`}>{ele.key}</label>
        </div>
      ))}
      </div>
      {required ? (
        <div style={{ display: "none" }}>
          <RadioValidator
            name={name}
            value={formData[name]}
            isTouched={isTouched}
            labelName={labelName}
            required={required}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Radio;