import React, { useEffect, useRef, useState } from "react";
import Button from "../../Components/Button/Button";
import Text from "../../Components/Text/Text";
import { AuthGet, AuthPost, Get, Post } from "../../common_var/httpService";
import { useLocation, useParams } from "react-router-dom";
// import { DecodeVinValues } from "@shaggytools/nhtsa-api-wrapper";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import Radio from "../../Components/Radio/Radio";
import Accordion from "react-bootstrap/Accordion";
import { unstable_composeClasses } from "@mui/material";
import { hasNullValueInObject } from "../../common_var/util";

const VehicleInformation = ({ step }) => {
  const [
    {
      formData,
      errors,
      formSubmitted,
      isCosignorFlow,
      currentStep,
      frames,
      isFullPreview,
      requiredFields,
    },
    dispatch,
  ] = useStateValue();
  const { loanid } = useParams();
  const params = useLocation();
  const cosignor = params.pathname.split("/")[1];
  // console.log('cosignor::: ', cosignor);
  const userId = sessionStorage.getItem("user_id");
  const [cartypes, setCartypes] = useState({
    Used: false,
    New: false,
    Preowned: false,
  });

  let data = [
    { value: "Used", key: "Used", id: 1 },
    { value: "New", key: "New", id: 2 },
    { value: "Preowned", key: "Pre Owned", id: 3 },
  ];

  const [updatedInfo, setUpdatedInfo] = useState([]);

  // const VIN = async () => {
  //   try {
  //     const res = await DecodeVinValues(formData.vin);
  //     dispatch({
  //       type: "SET_VALUES",
  //       payload: {
  //         year: res?.Results[0]?.ModelYear,
  //         make: res?.Results[0]?.Make,
  //         model: res?.Results[0]?.Model,
  //       },
  //     });
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   getDetails();
  //   if (formData.vin?.length == 17) {
  //     VIN();
  //   }
  //   if (formData.vin?.length == 17) {
  //     document.getElementById("vin").blur();
  //   }
  // }, [formData.vin]);

  const getDetails = async () => {
    try {
      let res = await AuthGet(`loan/vehicle/${loanid}`, "onboarding");
      if (res.statusCode === 200) {
        setUpdatedInfo(res.data[0]);
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
        const { vehicle_type } = res.data[0];
        //for updating the radio button
        setCartypes((prevCartypes) => ({
          ...prevCartypes,
          [vehicle_type]: !prevCartypes[vehicle_type],
        }));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDetails();

    dispatch({
      type: "CLEAR_ERRORS",
    });
    dispatch({
      type: "SET_VALUES",
      payload: { vehicle_type: "Preowned" },
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    try {
      // formData.milege = +formData.milege;

      const isFilled = hasNullValueInObject({...formData }, requiredFields);
      if(!isFilled) {
        console.log('isFilled::: ', isFilled);
        return;
      }

      const bodyData = {
        ...formData,
        loan_id: loanid,
        step,
      };
      if (Object.keys(errors).length === 0) {
        const resp = await AuthPost("loan/vehicle", bodyData, "onboarding");
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({type: "RESET_REQUIRED_FIELDS"});
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow
              ? resp.data[0].active_cosignor_step
              : resp.data[0].step,
          });

          const nextFrame = frames.filter((el) => el.step === currentStep + 1);
          dispatch({
            type: "SET_CURRENT_FRAME",
            payload: nextFrame[0]?.name,
          });
          // localStorage.setItem("userid",user_id);
        }
      }
    } catch (error) {}
  };

  const handleCarClick = (carType) => {
    setCartypes((prevCartypes) => ({
      // ...prevCartypes,
      [carType]: !prevCartypes[carType],
    }));
    if (carType === updatedInfo?.vehicle_type) {
      dispatch({
        type: "SET_VALUES",
        payload: updatedInfo,
      });
    } else {
      dispatch({
        type: "SET_VALUES",
        payload: {
          model: "",
          make: "",
          vin: "",
          mytrim: false,
          trim: "",
          year: "",
          milaege: 0,
          details: "",
          vehicle_type: carType,
        },
      });
    }
  };

  const handleToggleAccordian = (e, car) => {
    e.preventDefault();
    handleCarClick(car);
    dispatch({
      type: "CLEAR_ERRORS",
    });
    // if (Object.keys(formData).length !== 0) {
    //   dispatch({
    //     type: "CLEAR_VALUES",
    //   });
    // }
  };

  return (
    <>
      <div className="mb_24">
        <div>
          <Radio
            labelName={"Select Car Type"}
            name={"vehicle_type"}
            handleChange={handleChange}
            data={data}
            required={true}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
          />
        </div>
        <form className="row">
          <Text
            labelName={"VIN"}
            maxLen="17"
            name="vin"
            placeholder={"VIN"}
            handleChange={handleChange}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
          />
          <Text
            labelName={"Year"}
            name="year"
            maxLen="4"
            placeholder={"Year"}
            handleChange={handleChange}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
          />
          <Text
            labelName={"Make"}
            name="make"
            placeholder={"Make"}
            handleChange={handleChange}
            required={true}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
          />
          <Text
            labelName={"Model"}
            name="model"
            placeholder={"Model"}
            handleChange={handleChange}
            required={true}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
          />
          <Text
            labelName={"TRIM"}
            name="trim"
            placeholder={"TRIM"}
            handleChange={handleChange}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
          />
          <Text
            name="mileage"
            labelName={"Mileage"}
            placeholder={"Mileage"}
            handleChange={handleChange}
            readonly={ cosignor === 'cosignor' || isFullPreview ? true : false}
            // trim={true}
          />
          <Button
            value={"Continue"}
            type={"submit"}
            handleClick={handleSubmit}
          />
        </form>
      </div>
    </>
  );
};

export default VehicleInformation;
