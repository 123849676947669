import React, { useEffect, useRef, useState } from "react";
import DateValidator from "./dateValidator";
import { useStateValue } from "../../Context/StateProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

const Date = ({
  name,
  handleChange,
  labelName,
  placeholder,
  required,
  maxDate,
  minDate,
  minYear,
  readonly,
  dateFormat = "MM/DD/YYYY",
  condition
}) => {
  const [{ formData, errors, isFullPreview }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  const [status, setStatus] = useState(false)

  const handleDOB = (val) => {
    let modifiedDate = new window.Date(window.Date.UTC(val?.$d.getFullYear(),val?.$d.getMonth(),val?.$d.getDate()));
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: modifiedDate ? modifiedDate : "" },
    });
  };

  return (
    <div className="col-md-6 mb_24">
      {errors[name] === "Invalid" ? (
        <label htmlFor={name} className="labelText">
          <span className="requiredText">{errors[name]}</span> {labelName}
        </label>
      ) : errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
          {/* { name == "date_of_hire" && formData[name] > maxDate && 
          <span className="requiredText">Hire date should not be within last 60 days</span> } */}
        </label>
        
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          InputProps={{readOnly: readonly}}
          inputFormat={dateFormat}
          readOnly={readonly}
          openTo="year"
          minDate={minDate}
          onOpen={()=>setStatus(!status)}
          onClose={()=>setStatus(!status)}
          open={status}
          id={name}
          className="MainInput customDatapicker"
          name={name}
          value={formData[name] === undefined ? null : formData[name]}
          maxDate={maxDate}
          onChange={(newValue) => handleDOB(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={() => setIsTouched(true)}
              onClick={() => setStatus(!status)}
              style={errors[name] ? { border: "1px solid #f44336" } : null}
            />
          )}
        />
      </LocalizationProvider>
      {required && !isFullPreview ? (
        <div style={{ display: "none" }}>
          <DateValidator
            minYear={minYear}
            name={name}
            maxDate={maxDate}
            value={formData[name]}
            isTouched={isTouched}
            labelName={labelName}
            condition={condition}
            required={required}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Date;
