import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { useStateValue } from "../../Context/StateProvider";
import SkipButton from "../../Components/SkipButton/SkipButton";
import { hasNullValueInObject } from "../../common_var/util";

const PropertyOwnership = ({ step }) => {
  const [
    { formData, errors, frames, currentStep, isFullPreview, isCosignorFlow, requiredFields },
    dispatch,
  ] = useStateValue();
  const { loanid } = useParams();

  const type_of_residence = [
    { key: "self-owned", value: "Self-Owned" },
    { key: "co-owner", value: "Co-Owner" },
    { key: "rented", value: "Rented" },
    { key: "others", value: "Others" },
  ];
  const user_id = sessionStorage.getItem("user_id");

  const getDetails = async () => {
    try {
      let res = await AuthGet(`loan/fetch-assetinfo/${loanid}`, "onboarding");
      if (res.statusCode === 200) {
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !isFullPreview && getDetails();
    dispatch({
      type: "CLEAR_ERRORS",
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });

    const isFilled = hasNullValueInObject({...formData }, requiredFields);
    if(!isFilled) {
      console.log('isFilled::: ', isFilled);
      return;
    }

    try {
      const bodyData = {
        ...formData,
        loan_id: loanid,
        step: step,
      };
      if (Object.keys(errors).length === 0) {
        const resp = await AuthPost("loan/assetinfo", bodyData, "onboarding");
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({type: "RESET_REQUIRED_FIELDS"});
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow
              ? resp.data[0].active_cosignor_step
              : resp.data[0].step,
          });
          const nextFrame = frames.filter((el) => el.step === currentStep + 1);
          dispatch({
            type: "SET_CURRENT_FRAME",
            payload: nextFrame[0]?.name,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="row">
        <Dropdown
          required={true}
          data={type_of_residence}
          labelName={"Type of Residence"}
          name={"type_of_residence"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
        />

        {!isFullPreview && (
          <div className="displayFlex">
            <SkipButton />
            <Button
              type={"submit"}
              value={"Continue"}
              handleClick={handleSubmit}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default PropertyOwnership;
