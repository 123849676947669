import { useStateValue } from "../../Context/StateProvider";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Text from "../Text/Text";
import Email from "../Email/Email";
import Phone from "../Phone/Phone";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Button from "../Button/Button";

export const CoborrowerModal = ({ showCoborrowerPopup, setShowCoborrowerPopup ,setHide }) => {
    const [{ formData, isCosignorFlow }, dispatch] = useStateValue();
    // const [showCoborrowerPopup, setShowCoborrowerPopup] = useState(false);
    const { loanid } = useParams();


    useEffect(() => {
        isCosignorFlow === false && getCosignorInfo();
      }, [showCoborrowerPopup]);

    const getCosignorInfo = async () => {
        try {
          const resp = await AuthGet(
            `loan/get-cosignor-basicinfo/${loanid}`,
            "onboarding"
          );
          if (resp.statusCode === 200) {
            const { firstname, lastname, email, phone, middle_initial } =
              resp?.data[0];
            formData.coSignor_firstname = firstname;
            formData.coSignor_lastname = lastname;
            formData.coSignor_middle_initial = middle_initial;
            formData.coSignor_email = email;
            formData.coSignor_phone = phone;
          }
        } catch (error) {
          console.log("error::: ", error);
        }
    };

    const handleCosignor = async () => {
        try {
            const bodyData = {
            loan_id: loanid,
            cosignor_firstname: formData.coSignor_firstname,
            cosignor_lastname: formData.coSignor_lastname,
            cosignor_middle_initials: formData.coSignor_middle_initial?.trim(),
            cosignor_email: formData.coSignor_email,
            cosignor_phone: formData.coSignor_phone,
            };
            const resp = await AuthPost(
            `loan/save-cosignor-basicinfo`,
            bodyData,
            "onboarding"
            );

            console.log("RES", resp);
            if (resp.statusCode === 200) {
              toast.success(resp.message)
            setShowCoborrowerPopup(false);
            setHide(false)
            } else if (resp.statusCode === 400) {
            toast.error(resp.message);
            return;
            }
        } catch (error) {
            console.log("error::: ", error);
        }
        };
    
      const handleCloseCoBorrowerModal = () => {
        setShowCoborrowerPopup(false);
        formData.coSignor_firstname = "";
        formData.coSignor_lastname = "";
        formData.coSignor_middle_initial = "";
        formData.coSignor_email = "";
        formData.coSignor_phone = "";
        dispatch({
          type: "CLEAR_ERRORS",
        });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        let val = e.target.value;
    
        dispatch({
          type: "SET_VALUES",
          payload: { [name]: val },
        });
    };

    return (
        <>
            {/* co-borrower modal */}
        <Modal
            show={showCoborrowerPopup}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleCloseCoBorrowerModal}
        >
            <Modal.Header closeButton>Add Co-Borrower</Modal.Header>
            <Modal.Body>
            <div className="row">
            
                <Text
                    labelName={"first name"}
                    name={"coSignor_firstname"}
                    placeholder={"Cosigner First Name"}
                    trim={true}
                    required={true}
                    handleChange={handleChange}
                />
                <Text
                    name={"coSignor_middle_initial"}
                    labelName={"Middle Initials"}
                    placeholder={"Cosigner Middle Initials"}
                    handleChange={handleChange}
                />
                <Text
                    name={"coSignor_lastname"}
                    labelName={"Last Name"}
                    placeholder={"Cosigner Last Name"}
                    handleChange={handleChange}
                    required={true}
                    trim={true}
                />
                <Email
                    handleChange={handleChange}
                    labelName={"Email"}
                    placeholder={"Cosigner Email"}
                    name={"coSignor_email"}
                    required={true}
                />
                <Phone 
                international={true}
                defaultCountry={"US"}
                handleChange={handleChange}
                labelName={"Phone"}
                name={"coSignor_phone"}
                placeholder={"Cosignor Phone"}
                required={true}
                />
                <Button
                type={"submit"}
                value={"Submit"}
                handleClick={handleCosignor}
                />
            </div>
            </Modal.Body>
        </Modal>
        </>
    )
}