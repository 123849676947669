import React, { useCallback, useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import Brandlogo from "../../assets/images/brand_logo.svg";
import topheader from "../../assets/images/topheader-bg.png";
import footerbg from "../../assets/images/footer-bg.png";
import backtotop from "../../assets/images/backtotop.png";
import onboarding from "../../assets/images/onboarding.svg";
import offer from "../../assets/images/offer.svg";
import underwriting from "../../assets/images/undewriting.svg";
import kyc from "../../assets/images/kyc.svg";
import acceptoffer from "../../assets/images/accept-offers.svg";
import $ from "jquery";
import { SaveDraft } from "../../Templates/save-draft/SaveDraft";

const MultistepProgressbar = () => {
  const [{ globalStep, isCosignorFlow, framesLength }, dispatch] = useStateValue();
  const [steps, setSteps] = useState([]);
  const framesLen = [];
  const lastFrame = [];
  const lastFrameMinusOne = [];
  const lastFrameMinusTwo = [];

  const constructStepArr = () => {
    for (let i = 1; i <= framesLength; i++) {
      if (i === framesLength) {
        lastFrame.push(i);
        setSteps([
          { id: 1, name: "User Onboarding", step: [1],logo:onboarding },
          { id: 2, name: "KYC", step: framesLen,logo:kyc },
          { id: 3, name: "Underwriting", step: lastFrameMinusTwo,logo:underwriting },
          { id: 4, name: "Offers", step: lastFrameMinusOne,logo:offer },
          { id: 5, name: "Accept offers", step: lastFrame,logo:acceptoffer },
        ]);
      } else if (i === framesLength - 1) {
        lastFrameMinusOne.push(i);
      } else if (i === framesLength - 2) {
        lastFrameMinusTwo.push(i);
      } else {
        framesLen.push(i);
      }
    }
  };

  useEffect(() => {
    constructStepArr();
  }, [framesLength]);

  $(document).ready(function () {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("myHeader");
    var sticky = header?.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header?.classList?.add("stickyMenu");
      } else {
        header?.classList?.remove("stickyMenu");
      }
    }
  });

  $(function () {
    $.fn.scrollToTop = function () {
      $(this).hide().removeAttr("href");
      if ($(window).scrollTop() != "0") {
        $(this).fadeIn("fast");
      }
      var scrollDiv = $(this);
      $(window).scroll(function () {
        if ($(window).scrollTop() == "0") {
          $(scrollDiv).fadeOut("fast");
        } else {
          $(scrollDiv).fadeIn("fast");
        }
      });
      $(this).click(function () {
        $("html, body").animate({ scrollTop: 0 }, "fast");
      });
    };
  });

  $(function () {
    $("#toTop").scrollToTop();
  });

  return (
    <>
       <div className="leftMenuSection">
        {/* <div className="headerbg">
        <img src={topheader} />
      </div>

      <div className="footerbg">
        <img src={footerbg} />
      </div> */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 logo-header brand_logo">
              <img src={Brandlogo} />

              {/* <div> Logout</div> */}
            </div>
          </div>
        </div>

        <div
          className="container-fluid topheader  stepHeader"
          style={isCosignorFlow ? { padding: "15px 0" } : { padding: "" }}
          id="myHeader"
        >
          {/* <div className="brand_logo">
          <img src={Brandlogo} className="stickyLogo" />
        </div> */}

          {/* {!isCosignorFlow && ( */}
            <div className="multistepProgressbar">
              <ul>
                {steps.map((ele, index, arr) => {
                  return (
                    <li
                      className={
                        ele.step[ele.step.length - 1] === 1 &&
                        globalStep === ele.step[ele.step.length - 1]
                          ? "complete"
                          : globalStep > ele.step[ele.step.length - 1]
                          ? "complete"
                          : ele.step.includes(globalStep)
                          ? "ready"
                          : "default " 
                      }
                      key={ele.id}
                    >
                      <div className="menuInnerSection">
                        <div className="displayFlex">
                          <button>
                            {/* <span> {ele.id}</span> */}
                            <span>  <img src={ele?.logo} /></span>
                            
                             {/* <span>{ele?.logo ?? '22 '}</span> */}
                            
                          </button>

                          <h5>{ele.name}</h5>
                        </div>
                        <div>
                          <div
                            className={
                              ele.step[ele.step.length - 1] === 1 &&
                              globalStep === ele.step[ele.step.length - 1]
                                ? "completedButton"
                                : globalStep > ele.step[ele.step.length - 1]
                                ? "completedButton" : ele.step.includes(globalStep) ?  "inprogressButton" : ""
                            }
                          >
                            {ele.step[ele.step.length - 1] === 1 &&
                            globalStep === ele.step[ele.step.length - 1]
                              ? "Completed"
                              : globalStep > ele.step[ele.step.length - 1]
                              ? "Completed"
                              : ele.step.includes(globalStep)
                              ? "In Progress"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          {/* )} */}

          {/* <div><SaveDraft /></div> */}
        </div>

        <div className="backtotop" id="toTop">
          <img src={onboarding} />
        </div>
        {/* <img src="http://cursowebdesign.com/assets/img/to-top.png" alt="Back to top"/> */}
      </div>
    </>
  );
};

export default MultistepProgressbar;
