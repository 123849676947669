import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CoborrowerModal } from "../../Components/Coborrower-modal/Coborrower-modal";
import Offernotavailable from "../../assets/offer_notavailable.svg"
import { io } from "socket.io-client";
const Offers = ({ step }) => {
  const [{ offers, formData, frames, currentStep, currency, isCosignorFlow }, dispatch] = useStateValue();
  const { loanid, token } = useParams();
  const [offErr, setOffErr] = useState(false);
  const navigate = useNavigate();
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false);
  const [showCoborrowerPopup, setShowCoborrowerPopup] = useState(false);
  const [nooffer, setNooffer] = useState(null);
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    sessionStorage.setItem("borrower_token", token);



    // Decode the JWT
    const parts = token.split('.');

    const encodedPayload = parts[1];
    const decodedPayload = atob(encodedPayload); // Base64Url decode

    // Parse the JSON payload
    const payload = JSON.parse(decodedPayload);

    // Access the user ID claim
    const userId = payload.user_id; // Replace 'userId' with the actual claim name
    sessionStorage.setItem("UserId", userId);
    console.log('User ID:', userId);
    setUserId(userId)
    sessionStorage.setItem("login", true);
    connectSocket()
    dispatch({
      type: 'CLEAR_VALUES'
    })
  }, []);

  const connectSocket = () => {
    let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
      reconnection: false,
    });
    dispatch({
      type: "SET_SOCKET",
      payload: socket,
    });
    socket?.emit("newUser", userId);
    console.log(socket)
  }

  const getSelectedOffer = async () => {
    try {
      let resp = await AuthGet(`loan/fetch-chosenOffer/${loanid}`, "onboarding");
      dispatch({
        type: "SET_VALUES",
        payload: { offer_id: resp?.data?.id, loanamount: resp?.data?.financialamount },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSelectedOffer();
    if (offers.length === 0) {
      console.log("ERR");
      getSystemGenerateOffers();
    }
  }, []);

  useEffect(() => {
    if (formData.loanamount && formData.offer_id) {
      setOffErr(false);
    }
  }, [formData?.loanamount, formData?.offer_id]);

  let url = window.location.href;

  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;

  const handleRedirectHome = () =>{
    if(!isLocalhost)
    {
      window.open(
        `${url.split("//")[0]}//${url.split("-")[1].split(".")[0]}.${
          process.env.REACT_APP_DOMAIN
        }/borrower/login`,
        "_self"
      )
    } else{

      window.open(
        `${process.env.REACT_APP_UI_URL}/borrower/login`,
        "_self"
      );
    }  
    sessionStorage.clear();


  }

  const getSystemGenerateOffers = async () => {
    let res = await AuthGet(`loan/originate-offer/${step}/${loanid}`, "onboarding");
    console.log(res);
    setNooffer('dataavailable')
    if (res["statusCode"] == 200) {
      let data = res["data"];
      console.log("Offers data:::", data);
      if (data.length > 0) {
        dispatch({
          type: "SET_OFFERS",
          payload: data,
        });
      } else {
        setTimeout(() => handleRedirectHome(), 6000)
      }
    } else {
      console.log("Invalid");
    }
  };

  const handleSelect = async (offer_id, loanAmount) => {
    console.log(offer_id);
    let data = {
      offer_id: offer_id,
      loanamount: loanAmount,
    };
    setEnableSubmitBtn(true);
    dispatch({
      type: "SET_VALUES",
      payload: data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.loanamount && formData.offer_id) {
      setOffErr(false);
    } else {
      setOffErr(true);
    }

    if (!enableSubmitBtn) {
      toast.error("Select Offer");
      return;
    }

    try {
      if (formData.loanamount && formData.offer_id) {
        formData.step = step;
        console.log("OFFERS_FORMDATA", formData);
        let res = await AuthPost(
          `loan/store-suitable-Offer/${loanid}`,
          formData,
          "onboarding"
        );
        if (res.statusCode === 200) {
          // dispatch({
          //   type: "SET_STEP",
          //   payload: res.data[0].step,
          // });
          // dispatch({
          //   type: "SET_CURRENT_STEP",
          //   payload: res.data[0].step,
          // });

          // const nextFrame = frames.filter(el => el.step === currentStep + 1);
          // dispatch({
          //   type: 'SET_CURRENT_FRAME',
          //   payload: nextFrame[0]?.name,
          // });
          handleSaveContract();
        } else {
          // console.warn('===========>',res)
          toast.error(res.message[0]);
        }
      } else {
        toast.error("Please select an offer");
        setOffErr(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveContract = async () => {
    try {
      let res = await AuthPost(
        `loan/save-onboarding-form/${step}/${loanid}`,
        {},
        "onboarding"
      );
      console.log("res::: ", res);
      if (res.statusCode === 200) {
        !isCosignorFlow &&
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow ? res?.active_cosignor_step : res?.step,
          });
        navigate(`/acceptance/${loanid}/${token}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="offers_frame">
        {offers?.length > 0 ? <div className="row mb_24">
          <h3>Please Select Offer</h3>
          {offers.map((ele, index) => (
            <div
              className="col-md-3"
              key={index}
              onClick={() => handleSelect(ele.id, ele.financialamount)}
            >
              <label
                htmlFor={ele.id}
                className="offerSection"
                style={offErr ? { border: "1px solid red" } : null}
              >
                <div className="displayFlex spaceBetween mb_16 gap6">
                  <div className="word_breakall">
                    <p
                      name="offers"
                      className="fs16px fontWeight600 loanamt_text mb_4"
                    >
                      Loan Amt - {currency}{ele.financialamount}
                    </p>

                    <p name="offers" className="fs14px fontWeight500 ">
                      {" "}
                      {currency}{ele.monthlyamount}/ mo
                    </p>
                  </div>
                  <div className="radioInput">
                    <input
                      type="radio"
                      name="offers"
                      id={ele.id}
                      checked={formData["offer_id"] === ele.id ? true : false}
                    />
                    <label htmlFor={ele.id}></label>
                  </div>
                </div>

                <div className="displayFlex AlignItemCenter spaceBetween">
                  <div className="word_breakall centerline_after">
                    <p name="offers" className="fs14px fontWeight500">
                      <span className="harbourFogClr">Tenor </span>{" "}
                      {ele.duration}
                    </p>
                  </div>
                  {/* <div className="word_breakall centerline_after">
                </div> */}
                  <div className="word_breakall">
                    <p name="offers" className="fs14px fontWeight500">
                      APR %{ele.interestrate}
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div> :
          nooffer ?
            <div>
              <div className="displayFlex justifycontencenter">
                <div className="no_offer shadow">


                  <img className="no_offerimg" src={Offernotavailable} alt="" />
                  <h2> No offers available please contact your lender. Thank you</h2>


                </div>
              </div>
            </div> : ''}

        {offers?.length > 0 ? <div className="displayFlex flexEnd mt_20">
          <Button type="submit" value="Continue" handleClick={handleSubmit} />
        </div> :
          ''}
        {/* <hr /> */}
        {/* <div className="displayFlex flexEnd mt_20">
          {isCosignorFlow === false && (
            <Button
              type="button"
              value="Add co-borrower"
              handleClick={() => setShowCoborrowerPopup(true)}
            />
          )}
          &nbsp;&nbsp;&nbsp;
          <Button
            type="submit"
            value="Submit Application"
            handleClick={handleSubmit}
          />
        </div> */}
      </div>

      {/* <CoborrowerModal showCoborrowerPopup={showCoborrowerPopup} setShowCoborrowerPopup={setShowCoborrowerPopup} /> */}

    </>
  );
};

export default Offers;
