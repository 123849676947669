import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../Components/Button/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import Text from "../../Components/Text/Text";
import Email from "../../Components/Email/Email";
import Phone from "../../Components/Phone/Phone";
import { Get } from "../../common_var/httpService";

const ContractSignature = ({ step }) => {
  const [htmldata, setHtmlData] = useState(null);
  const [showSignPad, setSignPad] = useState(false);
  const [showCoborrowerPopup, setShowCoborrowerPopup] = useState(false);
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(true);
  const [{ signature, formData, isCosignorFlow, socket }, dispatch] = useStateValue();
  const { loanid, token } = useParams();
  const signRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // checkToken();
    getPromissoryNote();
    dispatch({
      type: "CLEAR_VALUES",
    });
  }, []);

  // const checkToken = async () => {
  //   try {
  //     const resp = await Get(
  //       `loan/verify-token/${loanid}/${token}`,
  //       'onboarding'
  //     );
  //     if (resp.statusCode === 200) {
  //       sessionStorage.setItem('borrower_token', resp.data.token);
  //       sessionStorage.setItem('user_id', resp.data.userId);
  //       getPromissoryNote();
  //     } else {
  //       toast.error('Invalid Token');
  //       // window.open(`${process.env.REACT_APP_UI_URL}/borrower/login`, '_self');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getPromissoryNote = async () => {
    try {
      let res = await AuthGet(
        `loan/fetch-promissoryNote/${loanid}`,
        "onboarding"
      );
      if (res.statusCode === 200) {
        setHtmlData(res.data);
        if (res.isSigned) {
          setEnableSubmitBtn(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let val = e.target.value;

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSignPad(false);
    if (!signRef.current._sigPad._isEmpty) {
      // console.log(signRef.current.getTrimmedCanvas().toDataURL());
      let data = signRef.current.getTrimmedCanvas().toDataURL();
      try {
        let res = await AuthPost(
          `loan/store-promissoryNote-signature/${loanid}`,
          { signature: data },
          "onboarding"
        );

        if (res.statusCode === 200) {
          setHtmlData(<></>);
          setHtmlData(res.data);
          setEnableSubmitBtn(false);
          toast.success("Thanks for the Acceptance");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Please sign");
    }
  };

  const handleClear = () => {
    signRef.current.clear();
  };

  let cancelbuttonstyle = {
    position: "absolute",
    marginRight: "48px",
    marginTop: "34px",
  };
  const handleSaveContract = async () => {
    if (enableSubmitBtn) {
      toast.error("Signature is required");
      return;
    }
    try {

      let res = await AuthPost(
        `loan/store-promissoryNote/${step}/${loanid}`,
        {},
        "onboarding"
      );
      console.log("res::: ", res);
      if (res.statusCode === 200) {
        !isCosignorFlow &&
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow ? res?.active_cosignor_step : res?.step,
          });
        sendNotification("AcceptanceCompleted", '')

      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendNotification = async (stage, body) => {
    let bodyData = {
      ...body, "stage": stage,
      "loan_id": loanid,
      "user_id": ''
    }
    console.log('bodyData', bodyData)
    await AuthPost(`socket/saveNotification`, bodyData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          if (res["data"].length > 0) {
            for (var user of res["data"]) {
              // console.log('sssssssss.........................', {
              //     receiver_user_id: user.user_id,
              //     notification: { id: user.user_id, 'message': user.content },
              // })
              socket?.emit("sendNotification", {
                receiver_user_id: user.user_id,
                notification: { id: user.user_id, 'message': user.content },
              });
            }
          }
          navigate("/thankyou");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message)
      })

  }

  const handleCloseCoBorrowerModal = () => {
    setShowCoborrowerPopup(false);
    formData.coSignor_firstname = "";
    formData.coSignor_lastname = "";
    formData.coSignor_middle_initial = "";
    formData.coSignor_email = "";
    formData.coSignor_phone = "";
    dispatch({
      type: "CLEAR_ERRORS",
    });
  };

  const getCosignorInfo = async () => {
    try {
      const resp = await AuthGet(
        `loan/get-cosignor-basicinfo/${loanid}`,
        "onboarding"
      );
      if (resp.statusCode === 200) {
        const { firstname, lastname, email, phone, middle_initial } =
          resp?.data[0];
        formData.coSignor_firstname = firstname;
        formData.coSignor_lastname = lastname;
        formData.coSignor_middle_initial = middle_initial;
        formData.coSignor_email = email;
        formData.coSignor_phone = phone;
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  // useEffect(() => {
  //   isCosignorFlow === false && getCosignorInfo();
  // }, [showCoborrowerPopup]);

  const handleCosignor = async () => {
    try {
      const bodyData = {
        loan_id: loanid,
        cosignor_firstname: formData.coSignor_firstname,
        cosignor_lastname: formData.coSignor_lastname,
        cosignor_middle_initials: formData.coSignor_middle_initial?.trim(),
        cosignor_email: formData.coSignor_email,
        cosignor_phone: formData.coSignor_phone,
      };
      const resp = await AuthPost(
        `loan/save-cosignor-basicinfo`,
        bodyData,
        "onboarding"
      );

      console.log("RES", resp);
      if (resp.statusCode === 200) {
        setShowCoborrowerPopup(false);
      } else if (resp.statusCode === 400) {
        toast.error(resp.message);
        return;
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const style = {
    maxHeight: "500px",
    overflowY: "auto",
    marginTop: "30px",
    border: "1px solid black",
  };

  return (
    <>
      <div className="offers_frame">
        {/* <h3>Acceptance</h3> */}
        <div style={style} dangerouslySetInnerHTML={{ __html: htmldata }}></div>

        <div className="displayFlex mt_20">
          <Button
            value="Click here to Sign"
            type="button"
            handleClick={() => setSignPad(true)}
          />
        </div>
        <hr />
        <div className="displayFlex flexEnd mt_20">
          {/* {isCosignorFlow === false && (
            <Button
              type="button"
              value="Add co-borrower"
              handleClick={() => setShowCoborrowerPopup(true)}
            />
          )} */}
          <Button
            type="submit"
            value="Back"
            handleClick={() => navigate(`/submit-application/${loanid}/${token}`)}
          />
          &nbsp;&nbsp;&nbsp;
          <Button
            type="submit"
            value="Submit Application"
            handleClick={handleSaveContract}
          />
        </div>
      </div>

      {/* co-borrower modal */}
      <Modal
        show={showCoborrowerPopup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseCoBorrowerModal}
      >
        <Modal.Header closeButton>Add Co-Borrower</Modal.Header>
        <Modal.Body>
          <div className="row">
            <Text
              labelName={"first name"}
              name={"coSignor_firstname"}
              placeholder={"Cosigner First Name"}
              trim={true}
              required={true}
              handleChange={handleChange}
            />
            <Text
              name={"coSignor_middle_initial"}
              labelName={"Middle Initials"}
              placeholder={"Cosinger Middle Initials"}
              handleChange={handleChange}
            />

            <Text
              name={"coSignor_lastname"}
              labelName={"Last Name"}
              placeholder={"Cosinger Last Name"}
              handleChange={handleChange}
              required={true}
              trim={true}
            />
            <Email
              handleChange={handleChange}
              labelName={"Email"}
              placeholder={"Cosinger Email"}
              name={"coSignor_email"}
              required={true}
            />

            <Phone
              international={true}
              defaultCountry={"US"}
              handleChange={handleChange}
              labelName={"Phone"}
              name={"coSignor_phone"}
              placeholder={"Cosignor Phone"}
              required={true}
            />
            <Button
              type={"submit"}
              value={"Submit"}
              handleClick={handleCosignor}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* signature modal */}
      <Modal
        className="modal_content"
        show={showSignPad}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Modal.Body style={{ width: "580px" }}>
          <div className="closebuttonblock">
            {" "}
            <button
              onClick={() => setSignPad(false)}
              className="btn btn-danger canvasclosebutton"
              style={cancelbuttonstyle}
            >
              X
            </button>
          </div>

          <div>
            <SignatureCanvas
              ref={signRef}
              canvasProps={{ width: 500, height: 250 }}
            />
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  type={"button"}
                  value="Clear"
                  handleClick={handleClear}
                />
                <Button
                  type={"submit"}
                  value="Continue"
                  handleClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <div></div>
    </>
  );
};

export default ContractSignature;
