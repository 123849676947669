import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import { isValidPhoneNumber } from "react-phone-number-input";

const PhoneValidator = ({ name, value, labelname, isTouched, required }) => {
  const [{ formData, errors, formSubmitted, requiredFields }, dispatch] = useStateValue();

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (!isValidPhoneNumber(value)) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is invalid` },
        });
      } else {
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);

  useEffect(() => {
    dispatch({
      type: 'SET_VALUES',
      payload: {[name]: value ?? null}
    });

    if(required) {
      const isExist = requiredFields?.find(item => item.name === name);
      !isExist && dispatch({
        type: "SET_REQUIRED_FIELDS",
        payload: { name, Fieldtype: "phone" } 
      })
    }
  }, [name]);

  return <></>;
};

export default PhoneValidator;
